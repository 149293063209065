// import { Link } from "react-router-dom";

import Screenshot from "./Screenshot";
import styles from "./Walkthrough.module.scss";

export default function Walkthrough() {
  return (
    <div className={styles["walkthrough"]}>
      <h1>Account Portal Walkthrough</h1>
      <p>
        Unlimited Academy uses MindBody as our scheduling, registration, and
        account management software. All information pertaining to student
        enrollment, attedance, and payment is available real time. You can use
        this portal to view your schedule, track your remaining programming, and
        update your account information. In order to provide a transparent and
        current view into all aspects of a student's account, it is imperitive
        that a parent or guardian knows how to log in, keeps their information
        up to date, and is familiar with the information provided.
      </p>
      <p className={styles["callout"]}>
        Once you have set up your initial login information, you can access the
        Unlimited Academy Account Portal directly at
        <br />
        <b>
          <a href="https://clients.mindbodyonline.com/classic/ws?studioid=837606">
            https://clients.mindbodyonline.com/classic/ws?studioid=837606
          </a>
        </b>
        <br />
        The initial screen will allow you to login or reset your password if you
        forgot it.
      </p>
      <p>
        We understand many parents have MindBody accounts for their personal
        use. Please note that this is <b>NOT</b> the Unlimited Academy Portal,
        but you can use your existing account to sign in and track your account
        at UNLTD. Our location can be linked as a preferred location or "place
        you visit" in your MindBody profile, and your child(ren) can be added as
        family members.
      </p>

      <h3>Initial Email from MindBody</h3>
      <p>
        Once your account has been created, you will recive automated email(s)
        from Mindbody with the subject{" "}
        <b>"Finish creating your Unlimited Tennis Academy account "</b>. Follow
        the link in that email to <b>Set Up Your Family Account</b>. <br />
        <br />
        <i>
          Please Note: If you recieved multiple emails, please contact{" "}
          <a href="mailto:registration@unltd-academy.com">
            registration@unltd-academy.com
          </a>{" "}
          so we can correct the issue.
        </i>
      </p>
      <Screenshot
        imgSrc="/img/screens/01-EmailInbox.png"
        imgAlt="Email From Mindbody"
      />
      <Screenshot
        imgSrc="/img/screens/02-EmailDetail.png"
        imgAlt="Link Within Email"
      />
      <h3>Create Your Account</h3>
      <p>
        Following the link in the email above will bring you to the screen
        below. Select the parent or guardian as the family account owner, and
        select "Next".
      </p>
      <Screenshot
        imgSrc="/img/screens/03-SelectPrimary.png"
        imgAlt="Select Account Owner"
      />
      <p>
        Confirm account owner information, making 100% sure the email matches
        the one you provided Unlimited Academy. Select "Next".
      </p>
      <Screenshot
        imgSrc="/img/screens/04-Create.png"
        imgAlt="Confirm Account Owner Information and Create Account Without Family"
      />
      <p>
        Add family members. Select "Add Another" if more spaces are needed.
        Select "Next".
      </p>
      <Screenshot
        imgSrc="/img/screens/05-AddFamily.png"
        imgAlt="Add Family Members"
      />
      <p>Verify that all family information is correct.</p>
      <Screenshot
        imgSrc="/img/screens/06-VerifyFamily.png"
        imgAlt="Verify Family Members"
      />
      <p>
        Set your password and select <b>"Create Your Account"</b>.
      </p>
      <Screenshot
        imgSrc="/img/screens/05-SetPassword.png"
        imgAlt="Set Your Password"
      />
      <p>
        Your account has been created. There is nothing further to do on this
        screen, and this window can be closed.
      </p>
      <Screenshot
        imgSrc="/img/screens/06-VerifyAccount.png"
        imgAlt="Confirmation of Account Creation"
      />
      <h3>Sign In To Unlimited Academy</h3>

      <p>
        Open a new browser tab and enter the following URL.
        <br />
        <b>
          <a href="https://clients.mindbodyonline.com/classic/ws?studioid=837606">
            https://clients.mindbodyonline.com/classic/ws?studioid=837606
          </a>
        </b>
        <br />
        <br />
        This is the URL you will use to manage and view all your account details
        at Unlimited Academy. Click the orange "Sign In" button in the middle of
        the screen.
      </p>
      <Screenshot
        imgSrc="/img/screens/07-UNLTDLanding.png"
        imgAlt="UNLTD Landing Page"
      />
      <p>Use your email and password you just created to sign in.</p>
      <Screenshot
        imgSrc="/img/screens/08-SignInUNLTD.png"
        imgAlt="Enter Email and Password"
      />
      <h3>Update Profile & Add Family Members</h3>
      <p>
        After signing in, you will be presented with screens to add any missing
        profile information and sign the participation waiver.
      </p>
      <Screenshot
        imgSrc="/img/screens/08-CompleteProfile.png"
        imgAlt="Add Missing Profile Information"
      />

      <p>
        At this point, your family members have been added to your account. To
        toggle between each family member, select the drop-down at the top right
        of the screen. You can then select the appropriate family account to
        view upcoming schedule and visit history.
      </p>
      <Screenshot imgSrc="/img/screens/23.jpg" imgAlt="Family Account Toggle" />
      <h3>Update your Payment Information</h3>
      <p>
        As a security precaution, businesses in the MindBody network do not have
        access to payment information outside of their own location. Even if you
        have a payment method stored at MindBody, you will need to enter it
        here.
      </p>
      <Screenshot
        imgSrc="/img/screens/18.jpg"
        imgAlt="Academy billing info edit"
      />
      <h3>View & Email Your Upcoming Schedule</h3>
      <p>
        Your Academy private and group schedule is displayed real-time in the
        "My Schedule" section, which is accessible from the top navigation bar.
        This schedule can be sent to your email by selecting the highlighted
        text above the grid. Please contact{" "}
        <a href="mailto:registration@unltd-academy.com">
          registration@unltd-academy.com
        </a>{" "}
        if anything in this schedule appears incorrect.
      </p>
      <Screenshot imgSrc="/img/screens/19.jpg" imgAlt="Your Schedule" />
      <h3>View Your Visit History</h3>
      <p>
        View your prior visits by selecting "Visit History" from the top
        navigation bar. This will show all prior appointments and allow you to
        see how each Pricing Option was applied to each visit. Please contact{" "}
        <a href="mailto:registration@unltd-academy.com">
          registration@unltd-academy.com
        </a>{" "}
        if anything appears incorrect in your visit history.
      </p>
      <Screenshot imgSrc="/img/screens/20.jpg" imgAlt="Visit History" />
      <h3>View Your Account Balances & Payments</h3>
      <p>
        Your account balance is displayed real-time by selecting "Account" from
        the top navigation bar. This will show all programs included in your
        enrollment (Pricing Options), your payment schedule, as well as any past
        purchases or payments made. Please contact{" "}
        <a href="mailto:registration@unltd-academy.com">
          registration@unltd-academy.com
        </a>{" "}
        if anything in the account section appears incorrect.
      </p>
      <Screenshot
        imgSrc="/img/screens/22.jpg"
        imgAlt="Account Balance & Payment History"
      />
      <hr />
      <h3>Add Additional Family Members</h3>
      <p>
        Once you have completed the required information, you can always add
        additional family members as needed. To do this, select the "Edit" link
        associated with your Mindbody account.
      </p>
      <Screenshot
        imgSrc="/img/screens/09-EditMB.png"
        imgAlt="Edit Mindbody Account"
      />
      <p>
        Use your login information to sign in to your Mindbody account. You may
        notice a notification at the top of the page asking you if you would
        like to add family members to your profile. Select "Yes", then select
        "Add <i>Child Name</i> as family member."
      </p>
      <Screenshot
        imgSrc="/img/screens/10-AddFamilyAlert.png"
        imgAlt="Mindbody Account Landing Page"
      />
      <Screenshot
        imgSrc="/img/screens/11-AddFamily.png"
        imgAlt="Add Notification"
      />
      <p>
        You can verify as well as add family members by scrolling to the bottom
        of the page.
      </p>
      <Screenshot
        imgSrc="/img/screens/12-ConfirmFamily.png"
        imgAlt="Confirm Family Members Have Been Added"
      />
      <h2> Viewing a Child's Schedule or Past Visits on the MindBody App</h2>
      <p className={styles["alert"]}>
        Unfortunately, as a current constraint of the sysetm, it is not possible
        to view a child's upcoming or past clinic visits on the MindBody app.
        Please login to Unlimited Academy Portal as shown above to see this
        information. We do not currently recommend using the MindBody app.
      </p>
    </div>
  );
}
