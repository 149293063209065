import { Outlet } from "react-router-dom";
import Footer from "./../components/Footer/Footer";
import Header from "./../components/Header/Header";

import styles from "../App.module.css";

export default function RootLayout() {
  return (
    <div className={styles["app-container"]}>
      <header>
        <Header />
      </header>
      <main className={styles["content-container"]}>
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
