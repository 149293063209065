import styles from "./Screenshot.module.scss";

export default function Screenshot({ imgSrc, imgAlt, className }) {
  return (
    <div className={styles["screenshot"]}>
      <div className={styles["caption"]}>{imgAlt}</div>
      <img src={imgSrc} alt={imgAlt} className={className} />
    </div>
  );
}
