import * as React from "react";
import "./scss/styles.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Account from "./pages/Account";
// import Tennis from "./pages/Tennis";
// import Soccer from "./pages/Soccer";
import RootLayout from "./pages/Root";
import ErrorPage from "./pages/ErrorPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Account /> },
      { path: "/account", element: <Account /> },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
