import React from "react";
import { Link } from "react-router-dom";
import styles from "./Logo.module.scss";

export default function Logo() {
  return (
    <Link className={styles["logo-block"]} href="/">
      <svg
        viewBox="0 0 323.588 207.442"
        className={styles.logo}
        width={323}
        height={207}
      >
        <path
          d="M202.164 0L0 207.442h121.424L323.588 0H202.164z"
          className={styles.blade}
        ></path>
        <path
          d="M51.646 145.312a5.144 5.144 0 0 1-3.865 4.766q-3.217.735-6.43 1.515a2.937 2.937 0 0 1-3.851-2.948V62.351q-8.78 2.2-17.5 4.738v90.192c0 9.832 7.8 15.486 17.5 13.055q7.056-1.77 14.146-3.332c9.751-2.152 17.7-11.519 17.7-21.352V55.46q-8.876 1.615-17.7 3.559zm64.786-48.303q-7.2-18.105-14.383-36V50.63Q93.1 51.647 84.2 52.99v107.985q8.91-1.342 17.854-2.36v-47.741q7.183 17.893 14.383 36v10.32q8.962-.753 17.942-1.18V48.027q-8.979.427-17.942 1.181zm161.751 51.636a2.937 2.937 0 0 1-3.851 2.948q-5.138-1.246-10.294-2.382v-72.4q5.157 1.134 10.294 2.381a5.264 5.264 0 0 1 3.851 4.85zm0-86.294q-7.054-1.77-14.145-3.332-8.827-1.944-17.7-3.559v107.985q8.875 1.617 17.7 3.559 7.09 1.56 14.145 3.333c9.7 2.43 17.5-3.223 17.5-13.056v-72.4c0-9.822-7.8-20.1-17.5-22.531m-77.23-12.996q-8.077-.706-16.173-1.15V66q8.093.441 16.173 1.149v90.191q8.963.783 17.9 1.9V69.044q8.055 1 16.083 2.27V53.522q-8.026-1.269-16.083-2.271-8.934-1.111-17.9-1.9M169.041 47.6q-8.983-.2-17.97-.08V155.5q8.984-.125 17.97.08 9.6.218 19.19.809V138.6q-9.586-.591-19.19-.808z"
          className={styles.text}
        ></path>
      </svg>
    </Link>
  );
}
