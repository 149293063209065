import styles from "./Header.module.scss";
import { Link } from "react-router-dom";
import Logo from "../UI/Logo";

export default function Header() {
  const downloadPDF = () => {
    fetch("/downloads/master-schedule.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Unlimited Academy Master Calendar";
        alink.click();
      });
    });
  };

  return (
    <div className={styles["header"]}>
      <div className={styles["header-logo"]}>
        <Logo />
      </div>
      <div className={styles["header-nav"]}>
        <Link
          to="https://clients.mindbodyonline.com/consumermyinfo?studioid=837606"
          className={styles["link"]}
        >
          UNLTD ACCOUNT PORTAL
        </Link>
        <button id="downloadBtn" onClick={downloadPDF} value="download">
          MASTER CALENDAR DOWNLOAD
        </button>
      </div>
    </div>
  );
}
